<div class="row mt-4 fs-6">
    <div class="col-12 col-lg-5 text-center text-md-start px-0">
        {{ texts?.distribution}}
            <a [href]="refuseUrl" role="button" target="_blank" class="ps-2">
                <img [src]="refuseImageUrl" class="refuse" [alt]="texts?.refuse"/>
            </a>
            <br/>
        {{ texts?.distribution2}}
            <a [href]="revelationUrl" role="button" target="_blank" class="ps-2">
                <img [src]="revelationImageUrl" class="revelation" [alt]="texts?.revelation"/>
            </a>
            <br/>

    </div>
    <div class="col-12 col-lg-5 text-center text-md-end">
        {{ texts?.copyright}}
        <br/>
        <a [href]="('mailto:' + texts?.email)" role="button">
            {{ texts?.contact}} {{texts?.email}}
        </a>
    </div>
</div>


