import {Component, OnInit} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {ContentService} from "../services/content.service";
import {OrderButton} from "../models/order-button.model";
import {map, Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {ContentSelectors} from "../state/content.selectors";
import {ContentActions} from "../state/content.actions";
import {ContentState} from "../state/content.reducer";
import {Texts} from "../models/texts.model";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  texts: Observable<Texts | undefined>;
  orderButtons: Observable<OrderButton[]>;
  titleImageUrl =      './assets/wecouldnotdoanybetter.svg';
  preorderImageUrl =  './assets/book-order.png';

  mainTitle =         'We could not do any better';
  description =       '"We Could Not Do Any Better" is the book about the European straight edge through the oral history from the people that made it happen. Written by Jean-Paul Frijns and Marc Hanou. For those who were there and those who missed out.';

  constructor(private readonly store: Store<{content: ContentState}>, private title: Title, private meta: Meta, private contentService: ContentService) {
    this.store.dispatch(ContentActions.getTexts());
    this.store.dispatch(ContentActions.getOrderButtons());
    this.texts = this.store.select(ContentSelectors.selectTexts);
    this.orderButtons = this.store.select(ContentSelectors.selectOrderButtons);
    this.title.setTitle(this.mainTitle);
    this.meta.addTags([
      {
        name: "description",
        content: this.description
      },
      {
        name: 'author',
        content: 'Jean Paul Frijns, Marc Hanou and credited contributors'
      }
    ]);

  }

  ngOnInit() {

  }

  preOrder(): void {
    console.log("pre order!");
  }

}
