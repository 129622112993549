@if (orderButton?.linkOnlyButton) {
    <a type="button" [href]="orderButton?.linkOnlyButton" target="_blank" class="btn btn-lg btn-light fw-bold border-white bg-white text-uppercase w-100 fs-4 text-decoration-none" [title]="'Order ' + orderButton?.postageRegion">
        {{ orderButton?.postageRegion }}
    </a>
} @else {
    <form [name]="orderButton?.name" target="paypal" [action]="formAction"  [method]="formMethod">

        <!-- Identify your business so that you can collect the payments. -->
        <input type="hidden" name="business" [value]="orderButton?.business">

        <!-- Specify a PayPal Shopping Cart Add to Cart button. -->
        <input type="hidden" name="cmd" [value]="formCmd">
        <input type="hidden" name="add" [value]="formAdd">

        <!-- Specify details about the item that buyers will purchase. -->
        <input type="hidden" name="item_name" [value]="orderButton?.itemName">
        <input type="hidden" name="amount" [value]="orderButton?.amount">
        <input type="hidden" name="currency_code" [value]="orderButton?.currency">

        <!-- Display the payment button. -->
        <button type="submit" name="submit" class="btn btn-lg btn-light fw-bold border-white bg-white text-uppercase w-100 fs-4" [title]="'Order ' + orderButton?.postageRegion">
            {{ orderButton?.postageRegion }}
        </button>
    </form>
}

