import {createReducer, on} from "@ngrx/store";
import {ContentActions} from "./content.actions";
import {Texts} from "../models/texts.model";
import {OrderButton} from "../models/order-button.model";

export const CONTENT_STORE_KEY = 'content';

export type ContentState = {
    texts?: Texts;
    orderButtons: OrderButton[];
}

export const initialState: ContentState = {
    texts: undefined,
    orderButtons: [],
}

export const contentReducer = createReducer(
    initialState,
    on(ContentActions.getTextsSuccess, (state, { texts }) => ({
        ...state,
        texts: texts,
    })),
    on(ContentActions.getOrderButtonsSuccess, (state, { orderButtons }) => ({
      ...state,
      orderButtons: orderButtons,
    })),
);