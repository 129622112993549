import {OrderButton, OrderButtonTO} from "../models/order-button.model";
import {PostageRegionTypeMapper} from "./postage-region-type.mapper";
import {CurrencyMapper} from "./currency.mapper";

export class OrderButtonMapper {
    static toModel(dto: OrderButtonTO): OrderButton {
        console.log("MAP:", dto.currency); // e.g. 'Eur'
        return {
            postageRegion: PostageRegionTypeMapper.toModel(dto.postageRegion),
            name: dto.name,
            linkOnlyButton: dto.linkOnlyButton ?? undefined,
            business: dto.business,
            itemName: dto.itemName,
            amount: dto.amount,
            currency: CurrencyMapper.toModel(dto.currency),
        };
    }
}