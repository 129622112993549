import {CurrencyEnum, CurrencyEnumTO, PostageRegionEnum, PostageRegionEnumTO} from "../models/postage-type.model";


const currencyTypeMap: { [key in CurrencyEnumTO]: CurrencyEnum } = {
    [CurrencyEnumTO.Eur]: CurrencyEnum.EUR,
    [CurrencyEnumTO.Dollar]: CurrencyEnum.DOLLAR,
};

export class CurrencyMapper {
    static toModel(dto: CurrencyEnumTO): CurrencyEnum {
        return currencyTypeMap[dto];
    }
}