<div class="d-flex vh-100 w-100 mx-auto flex-column" *ngIf="texts | async as texts">
    <header></header>
    <main>
        <div class="container-xxl">
              <div class="row mx-lg-0">
                <div class="col-12 col-md-5 order-2 order-md-1 text-start mb-5 px-lg-0 left-side">
                    <img [src]="titleImageUrl" class="title"/>
                    <h1 class="visually-hidden">{{ texts.mainTitle }}</h1>
                    <h2 class="h4 mb-5 visually-hidden">{{ texts.subTitle }}</h2>
                    <p class="mb-4">{{ texts.description }} <span class="fw-bold">{{ texts.orderItNow }}</span></p>
                    <div>
                        <span class="text-primary text-black fw-bold fs-3">{{ texts.buyNow }}</span>
                    </div>

                    <p class="mb-5">
                        {{ texts.postage }}<br/>
                        {{ texts.europePostage }} <br/>
                        {{ texts.worldPostage }}
                    </p>
                    <div class="row gy-2 gx-3 mb-5">
                        <div *ngFor="let button of orderButtons | async" [classList]="'col-12 col-lg-4'">
                            <app-order-button [orderButton]="button"></app-order-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="mb-4">
                                <span class="fw-bold">
                                    {{ texts.noPaypal }}
                                </span>
                                {{ texts.noProblem }} <br/>
                                {{ texts.disclaimer1 }}
                                <a [href]="'mailto:'+texts.email" title="Send an email to: {{texts.email}}"> {{texts.email}}</a>
                                {{ texts.disclaimer2 }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7 order-1 order-md-2 px-lg-0 right-side">
                        <!-- desktop -->
                        <img class="d-none d-lg-block book" [src]="preorderImageUrl"  alt="{{ texts.book }}"/>
                        <!-- tablet -->
                        <img class="d-none d-md-block d-lg-none img-fluid w-100" [src]="preorderImageUrl"  alt="{{ texts.book }}"/>
                        <!-- mobile -->
                        <img class="d-md-none img-fluid w-75 mx-auto d-block" [src]="preorderImageUrl" alt="{{ texts.book }}"/>
                </div>
            </div>
        </div>
    </main>

    <footer class="bg-white">
        <div class="container-xxl h-100">
            <div class="row mx-lg-0">
                <div class="col">
                    <app-footer [texts]="texts"/>
                </div>
            </div>
        </div>
    </footer>
</div>

