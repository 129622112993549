import {contentReducer} from "./state/content.reducer";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {ContentEffects} from "./state/content.effects";
import {NgModule} from "@angular/core";

const staticReducers = {
    content: contentReducer,
}

@NgModule({
    imports: [
        StoreModule.forRoot(staticReducers, {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
        }),
        EffectsModule.forRoot([
            ContentEffects,
        ]),
    ],
    exports: [StoreModule],
})

export class AppStoreModule {}