import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderButton} from "../../models/order-button.model";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-order-button',
  templateUrl: './order-button.component.html',
  styleUrl: './order-button.component.scss'
})
export class OrderButtonComponent {
  @Input() orderButton?: OrderButton;
  @Output() startStopDateToggled = new EventEmitter<OrderButton>();
  readonly formAction = 'https://www.paypal.com/cgi-bin/webscr';
  readonly formMethod = 'post';
  readonly formCmd = '_cart';
  readonly formAdd = '1';


  constructor(private readonly store: Store) {}

  preOrder() {
    console.log("PRE-ORDER");
  }

}
