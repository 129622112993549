import {Texts, TextsTO} from "../models/texts.model";

export class TextsMapper {
    static toModel(dto: TextsTO): Texts {
        return {
            mainTitle: dto.mainTitle,
            subTitle: dto.subTitle,
            description: dto.description,
            orderItNow: dto.orderItNow,
            description2: dto.description2,
            buyNow: dto.buyNow,
            postage: dto.postage,
            europePostage: dto.europePostage,
            worldPostage: dto.worldPostage,
            europe: dto.europe,
            world: dto.world,
            noPaypal: dto.noPaypal,
            noProblem: dto.noProblem,
            disclaimer1: dto.disclaimer1,
            disclaimer2: dto.disclaimer2,
            book: dto.book,
            preOrderButton: dto.preOrderButton,
            distribution: dto.distribution,
            refuse: dto.refuse,
            distribution2: dto.distribution2,
            revelation: dto.revelation,
            copyright: dto.copyright,
            contact: dto.contact,
            email: dto.email
        }
    }
}