import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {NgModule} from "@angular/core";

export const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
    }
];

const RouterConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    anchorScrolling: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, RouterConfiguration)],
    exports: [RouterModule],
})
export class AppRoutingModule {}