import {Component} from '@angular/core';
import {NgbConfig} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
}
