import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {AppComponent} from "./app.component";
import {HomeComponent} from "./home/home.component";
import {AppRoutingModule} from "./app-routing.module";
import {FooterComponent} from "./components/footer/footer.component";
import {OrderButtonComponent} from "./components/paypal-button/order-button.component";
import {HttpClientModule} from "@angular/common/http";
import { StoreModule } from '@ngrx/store';
import {CONTENT_STORE_KEY, contentReducer} from "./state/content.reducer";
import {EffectsModule} from "@ngrx/effects";
import {ContentEffects} from "./state/content.effects";
import {AppStoreModule} from "./app-store.module";
import {LetDirective} from "@ngrx/component";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        OrderButtonComponent,
        FooterComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AppStoreModule,
        StoreModule.forRoot({ content: contentReducer }),
        StoreModule.forFeature(CONTENT_STORE_KEY, contentReducer),
        EffectsModule.forFeature([ContentEffects]),
        LetDirective,

    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
