import {PostageRegionEnum, PostageRegionEnumTO} from "../models/postage-type.model";

const postageRegionTypeMap: { [key in PostageRegionEnumTO]: PostageRegionEnum } = {
    [PostageRegionEnumTO.Europe]: PostageRegionEnum.EUROPE,
    [PostageRegionEnumTO.World]: PostageRegionEnum.WORLD,
    [PostageRegionEnumTO.Usa]: PostageRegionEnum.USA,
};

export class PostageRegionTypeMapper {
    static toModel(dto: PostageRegionEnumTO): PostageRegionEnum {
        return postageRegionTypeMap[dto];
    }
}