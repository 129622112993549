import {createAction, props} from "@ngrx/store";
import {Texts} from "../models/texts.model";
import {OrderButton} from "../models/order-button.model";

const getTexts = createAction('[CONTENT] getTexts');
const getTextsSuccess = createAction('[CONTENT] getTextsSuccess', props<{ texts: Texts}>());
const getOrderButtons = createAction('[CONTENT] getOrderButtons');
const getOrderButtonsSuccess = createAction('[CONTENT] getOrderButtonsSuccess', props<{ orderButtons: OrderButton[] }>());

export const ContentActions = {
    getTexts,
    getTextsSuccess,
    getOrderButtons,
    getOrderButtonsSuccess,
}
