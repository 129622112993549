import {Component, Input} from '@angular/core';
import {Texts} from "../../models/texts.model";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  @Input() texts?: Texts;
  refuseImageUrl = './assets/refuse.png';
  refuseUrl = 'https://refusemusic.net';
  revelationImageUrl = './assets/revhq.png';
  revelationUrl = 'https://www.revelationrecords.com';

}
