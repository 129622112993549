import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {OrderButton, OrderButtonTO} from "../models/order-button.model";
import {OrderButtonMapper} from "../mappers/order-button.mapper";
import {Texts, TextsTO} from "../models/texts.model";
import {TextsMapper} from "../mappers/texts.mapper";

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    constructor(private readonly httpClient: HttpClient) {
    }

    public getTexts(): Observable<Texts> {
        return this.httpClient.get<TextsTO>('/assets/texts.json')
            .pipe(map(
                TextsMapper.toModel
            ));
    }

    public getOrderButtons(): Observable<OrderButton[]> {
        return this.httpClient.get<OrderButtonTO[]>('/assets/order-buttons.json')
            .pipe(map(
                    dtos => dtos.map(
                        dto => OrderButtonMapper.toModel(dto)
                    )
                )
            );

    }

}