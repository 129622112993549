export const enum PostageRegionEnumTO {
    Europe = 'Europe',
    World = 'World',
    Usa = 'Usa',
}

export enum PostageRegionEnum {
    EUROPE = 'Europe',
    WORLD = 'World',
    USA = 'Usa'
}

export const enum CurrencyEnumTO {
    Eur = 'Eur',
    Dollar = 'Dollar'
}

export enum CurrencyEnum {
    EUR = 'EUR',
    DOLLAR = 'DOLLAR'
}