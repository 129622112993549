import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CONTENT_STORE_KEY, ContentState} from "./content.reducer";

const selectContentState = createFeatureSelector<ContentState>(CONTENT_STORE_KEY);
const selectTexts = createSelector(selectContentState, (state: ContentState) => state.texts ?? undefined);
const selectOrderButtons = createSelector(selectContentState, (state: ContentState) => state.orderButtons);

export const ContentSelectors = {
    selectContentState,
    selectTexts,
    selectOrderButtons,
}

