import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {ContentActions} from "./content.actions";
import {exhaustMap, map, switchMap} from "rxjs";
import {ContentService} from "../services/content.service";


@Injectable()
export class ContentEffects {
    constructor(
        private readonly contentService: ContentService,
        private readonly actions: Actions,
        private readonly store: Store,
    ) {
    }

    getTexts = createEffect(() => {
       return this.actions.pipe(
           ofType(ContentActions.getTexts),
           exhaustMap(() => this.contentService.getTexts()
               .pipe(
                   map(
                       texts => ContentActions.getTextsSuccess({ texts })
                   )
               )

           )
       )
    });

    getContents = createEffect(() => {
        return this.actions.pipe(
            ofType(ContentActions.getOrderButtons),
            exhaustMap(() => this.contentService.getOrderButtons()
                .pipe(
                    map(
                        orderButtons =>
                            ContentActions.getOrderButtonsSuccess({ orderButtons })
                    )
                )
            )
        )
    });
}
